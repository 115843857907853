import  axios   from 'axios';

export default {
    async getabos(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'abo.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            });

            // console.log(payload.sessiontoken);
            const notparsedResponse = response.data;
            // console.log(notparsedResponse);
            const parsedResponse = JSON.parse(notparsedResponse.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);
            
                const abos = [];
            if(parsedResponse.status != "error") {
                for (const key in parsedResponse) {
                    const aboline = {
                        id: parsedResponse[key].aboid,
                        abo_name: parsedResponse[key].abo_name,
                        abo_dauer: parsedResponse[key].abo_dauer,
                        erstellt: parsedResponse[key].erstellt,
                        bezahlt: parsedResponse[key].bezahlt,
                        bezahlt_seit: parsedResponse[key].bezahlt_seit,
                        miniserver_ip: parsedResponse[key].miniserver_ip,
                        rufnummer: parsedResponse[key].rufnummer,
                        Aktivierungskey: parsedResponse[key].Aktivierungskey,
                        Rufnummernbestaetigung: parsedResponse[key].Rufnummernbestaetigung,
                        Bezahltyp_id: parsedResponse[key].Bezahltyp_id,
                        is_expired: parsedResponse[key].is_expired
                    };
                    abos.push(aboline);
                }
            

            
            // console.log(context);
            }
            context.commit('setAbos', abos);
        } catch (error) {
            console.error(error);
        }
    },
    async getadminabos(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'adminabo.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            });

            // console.log(payload.sessiontoken);
            const notparsedResponse = response.data;
            // console.log(notparsedResponse);
            const parsedResponse = JSON.parse(notparsedResponse.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);
            
            const abos = [];
            for (const key in parsedResponse) {
                const aboline = {
                    id: parsedResponse[key].aboid,
                    abo_name: parsedResponse[key].abo_name,
                    abo_dauer: parsedResponse[key].abo_dauer,
                    erstellt: parsedResponse[key].erstellt,
                    bezahlt: parsedResponse[key].bezahlt,
                    bezahlt_seit: parsedResponse[key].bezahlt_seit,
                    miniserver_ip: parsedResponse[key].miniserver_ip,
                    rufnummer: parsedResponse[key].rufnummer,
                    Aktivierungskey: parsedResponse[key].Aktivierungskey,
                    Rufnummernbestaetigung: parsedResponse[key].Rufnummernbestaetigung,
                    Bezahltyp_id: parsedResponse[key].Bezahltyp_id,
                    is_expired: parsedResponse[key].is_expired
                };
                abos.push(aboline);
              }

            context.commit('setAdminAbos', abos);
            // console.log(context);

        } catch (error) {
            console.error(error);
        }
    },
    async getrechnungsdaten(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'getbilldata.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            reid: payload.reid,
            });

            // console.log(payload.sessiontoken);
            const notparsedResponse = response.data;
            console.log(notparsedResponse);
            const parsedResponse = JSON.parse(notparsedResponse.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);
            
            const billdatas = [];
            for (const key in parsedResponse) {
                const billdata = {
                    id: parsedResponse[key].aboid,
                    menge: parsedResponse[key].menge,
                    abo_name: parsedResponse[key].abo_name,
                    abo_dauer: parsedResponse[key].abo_dauer,
                    erstellt: parsedResponse[key].erstellt,
                    bezahlt: parsedResponse[key].bezahlt,
                    bezahlt_seit: parsedResponse[key].bezahlt_seit,
                    Abo_Preis: parsedResponse[key].Abo_Preis,
                    Preis_Mwst: parsedResponse[key].Preis_Mwst,
                    Preis_Netto: parsedResponse[key].Preis_Netto,
                    Vorname: parsedResponse[key].Vorname,
                    Bezahltyp_id: parsedResponse[key].Bezahltyp_id,
                    Nachname: parsedResponse[key].Nachname,
                    Email: parsedResponse[key].Email,
                    Strasse: parsedResponse[key].Strasse,
                    Hausnummer: parsedResponse[key].Hausnummer,
                    Ort: parsedResponse[key].Ort,
                    Plz: parsedResponse[key].Plz,
                    Land: parsedResponse[key].Land,
                    Bezahltypname: parsedResponse[key].Bezahltypname,
                    reNr: parsedResponse[key].reNr

                    
                };
                billdatas.push(billdata);
              }

            context.commit('setBillDatas', billdatas);
            console.log(context);

        } catch (error) {
            console.error(error);
        }
    }

};



