import  axios   from 'axios';

export default {
    async getuserdata(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'profile.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            });

            // console.log(payload.sessiontoken);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            // console.log(parsedResponse);
            
            const userdata = [];

            for (const key in parsedResponse) {
                const profileline = {
                    id: parsedResponse[key].id,
                    benutzername: parsedResponse[key].benutzername,
                    vorname: parsedResponse[key].vorname,
                    nachname: parsedResponse[key].nachname,
                    strasse: parsedResponse[key].strasse,
                    hausnummer: parsedResponse[key].hausnummer,
                    plz: parsedResponse[key].plz,
                    ort: parsedResponse[key].ort,
                    land: parsedResponse[key].land,
                    email: parsedResponse[key].email,
                    aktiv: parsedResponse[key].aktiv,
                    erstellt: parsedResponse[key].erstellt
                };
                userdata.push(profileline);
              }

            context.commit('setUserData', userdata);
            // console.log(context);

        } catch (error) {
            console.error(error);
        }
    },
    async getabotype(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'abotype.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            abotypeid: payload.abotypeid,
            });

            // console.log(payload.sessiontoken);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            // console.log(parsedResponse);
            
            const abotype = [];

            for (const key in parsedResponse) {
                const profileline = {
                    id: parsedResponse[key].id,
                    abo_name: parsedResponse[key].abo_name,
                    abo_beschreibung: parsedResponse[key].abo_beschreibung,
                    abo_dauer: parsedResponse[key].abo_dauer,
                    abo_preis: parsedResponse[key].abo_preis,
                };
                abotype.push(profileline);
              }

            context.commit('setAboType', abotype);
            // console.log(context);

        } catch (error) {
            console.error(error);
        }
    },
    async getabo(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'aboinfo.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            paymentsourceaboid: payload.paymentsourceaboid,
            });

            // console.log(payload.sessiontoken);
            const notparsedResponse = response.data;
            // console.log(notparsedResponse);
            const parsedResponse = JSON.parse(notparsedResponse.replace("<!DOCTYPE html>",""));
            // console.log(parsedResponse);
            
                const abos = [];
            if(parsedResponse.status != "error") {
                for (const key in parsedResponse) {
                    const aboline = {
                        id: parsedResponse[key].aboid,
                        abo_name: parsedResponse[key].abo_name,
                        abo_dauer: parsedResponse[key].abo_dauer,
                        erstellt: parsedResponse[key].erstellt,
                        bezahlt: parsedResponse[key].bezahlt,
                        bezahlt_seit: parsedResponse[key].bezahlt_seit,
                        miniserver_ip: parsedResponse[key].miniserver_ip,
                        rufnummer: parsedResponse[key].rufnummer,
                        Aktivierungskey: parsedResponse[key].Aktivierungskey,
                        Rufnummernbestaetigung: parsedResponse[key].Rufnummernbestaetigung,
                        Bezahltyp_id: parsedResponse[key].Bezahltyp_id,
                        is_expired: parsedResponse[key].is_expired
                    };
                    abos.push(aboline);
                }
            

            
            // console.log(context);
            }
            context.commit('setAboInfo', abos);
        } catch (error) {
            console.error(error);
        }
    },
    async testing(context,payload) {
        console.log('we start testing!');
        const httplink = context.state.serverlink+'userdataupdate.php?key=9999';
        console.log(httplink);
        const response = await axios.post(httplink, {
        userid: payload.userid,
        token: payload.token,
        vorname: payload.vorname,
        nachname: payload.nachname,
        benutzername: payload.benutzername,
        email: payload.email,
        strasse: payload.strasse,
        hausnr: payload.hausnr,
        land: payload.land,
        plz: payload.plz,
        ort: payload.ort,
        abotypeid: payload.abotypeid,
        paymentmethod: payload.paymentmethod,
        });

        console.log(payload.strasse);

        const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
        console.log(parsedResponse);
        const line = {
                    status: parsedResponse["status"],
                    message: parsedResponse["message"],
                };
        if(line.status == 'error') {
            throw new Error(line.message);
        }
        console.log(context);



        // create abo

        console.log('we start creating abo now!');
        console.log(payload.paymentmethod);


        const httplink2 = context.state.serverlink+'insertAboPaymentActivate.php?key=9999';
        console.log(httplink2);
        const response2 = await axios.post(httplink2, {
        userid: payload.userid,
        token: payload.token,
        abotypeid: payload.abotypeid,
        paymentsource: payload.paymentsource,
        paymentmethod: payload.paymentmethod,
        paymentsourceaboid: payload.paymentsourceaboid,
        rufnr: payload.rufnr,
        });

        console.log(payload.abotypeid);

        const parsedResponse2 = JSON.parse(response2.data.replace("<!DOCTYPE html>",""));
        console.log(parsedResponse2);
        const line2 = {
                    status: parsedResponse2["status"],
                    message: parsedResponse2["message"],
                };
        if(line2.status == 'error') {
            throw new Error(line2.message);
        }
        console.log(context);



    },
    
    async buyabo(context,payload) {
        console.log('we start buying!');


        const httplink = context.state.serverlink+'userdataupdate.php?key=9999';
        console.log(httplink);
        const response = await axios.post(httplink, {
        userid: payload.userid,
        token: payload.token,
        vorname: payload.vorname,
        nachname: payload.nachname,
        benutzername: payload.benutzername,
        email: payload.email,
        strasse: payload.strasse,
        hausnr: payload.hausnr,
        land: payload.land,
        plz: payload.plz,
        ort: payload.ort,
        abotypeid: payload.abotypeid,
        paymentmethod: payload.paymentmethod,
        });

        console.log(payload.strasse);

        const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
        console.log(parsedResponse);
        const line = {
                    status: parsedResponse["status"],
                    message: parsedResponse["message"],
                };
        if(line.status == 'error') {
            console.log('error throwed: '+ line.message);
            throw new Error(line.message);
        }
        // console.log(context);



        // create abo

        console.log('we start creating abo now!');
        console.log(payload.paymentmethod);


        if(payload.paymentmethod == 1) {
            // Paypal zweig

            // const httplink = 'https://api-m.sandbox.paypal.com/v1/oauth2/token';
            // console.log(httplink);
            // const response = await axios.post(httplink, {
            // userid: payload.userid,
            // token: payload.token,
            // Ab4iOIOQNhmsOEtux1LU03eVWBmgiyrR8C8ANVcLBdrt_tpYxgXb4bROHGy76Cc1v_p9PmqEwMUby2uL: "EG34Jsea7sT9QDbw4W_ynSjnXTmbaSQh-OT-Rj3M4RGFVqSxvC4JOpAxDLcPL9CS-rGmMLeELLlOI6eW",
            // Content-Type: "application/x-www-form-urlencoded",
            // grant_type: "client_credentials",
            // });

            console.log('Wir schicken den Paypal Request um Abo zu inserten');
            // zuerst Paypal zeugs durchlaufenlassen mit await
            // und nur dann das abo fertig machen, wenn die rückmeldung von paypal ok ist

            if (this.paypalresponseOk == true) {
                const httplink2 = context.state.serverlink+'insertAboPaymentActivate.php?key=9999';
                console.log(httplink2);
                const response2 = await axios.post(httplink2, {
                userid: payload.userid,
                token: payload.token,
                abotypeid: payload.abotypeid,
                paymentsource: payload.paymentsource,
                paymentmethod: payload.paymentmethod,
                paymentsourceaboid: payload.paymentsourceaboid,
                rufnr: payload.rufnr,
                });

                console.log(payload.paymentmethod);

                const parsedResponse2 = JSON.parse(response2.data.replace("<!DOCTYPE html>",""));
                console.log(parsedResponse2);
                const line2 = {
                            status: parsedResponse2["status"],
                            message: parsedResponse2["message"],
                        };
                if(line2.status == 'error') {
                    throw new Error(line2.message);
                }
                console.log(context);

            }

        } else if (payload.paymentmethod == 2) {
            // Bank zweig

            console.log('Wir schicken den Bank Request um Abo zu inserten');
            const httplink2 = context.state.serverlink+'insertAboPaymentActivate.php?key=9999';
            console.log(httplink2);
            const response2 = await axios.post(httplink2, {
            userid: payload.userid,
            token: payload.token,
            abotypeid: payload.abotypeid,
            paymentsource: payload.paymentsource,
            paymentmethod: payload.paymentmethod,
            paymentsourceaboid: payload.paymentsourceaboid,
            rufnr: payload.rufnr,
            });


            const parsedResponse2 = JSON.parse(response2.data.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse2);
            const line2 = {
                        status: parsedResponse2["status"],
                        message: parsedResponse2["message"],
                    };
            if(line2.status == 'error') {
                throw new Error(line2.message);
            }
            
            console.log('Request ist zu ende.'+line2.message);

        } else {
            console.log('paymentmethod not found!');
            
            console.log(payload.paymentmethod);
        }

        


    }

};