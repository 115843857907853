export default {
    Abos(state) {
        return state.abos;
    },
    AdminAbos(state) {
        return state.adminabos;
    },
    HasAdminAbos(state) {
        return state.adminabos && state.adminabos.length > 0;
    },
    HasAbos(state) {
        return state.abos && state.abos.length > 0;
    },
    HasBillDatas(state) {
        return state.billdatas && state.billdatas.length > 0;
    },
    BillDatas(state) {
        return state.billdatas;
    },
};