<template>
    <h2>
        Seite nicht gefunden!
    </h2>
    <p>Möglicherweise möchten Sie sich im<router-link to="/login" class="btn text-primary" >Shop</router-link>umsehen?</p>
    <!-- <router-link to="/shop" class="btn"  aria-current="page">
        <a class="nav-link" aria-current="page" href="#">
        <i class="fa fa-shop"></i>
            Shop</a>
    </router-link> -->
</template>