export default {
    isAuthenticated(state) {
        return !!state.sessiontoken;
    },
    userId(state) {
        return state.userId;
    },
    getToken(state) {
        return state.sessiontoken;
    },
    isAdmin(state) {
        // console.log(state.userId);
        if (state.userId == 1) {
            return true;
        } else {
            return false;
        }
    }
};