import  axios   from 'axios';

export default {
    async getprofile(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'profile.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            });

            // console.log(payload.sessiontoken);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);
            
            const profiles = [];

            for (const key in parsedResponse) {
                const profileline = {
                    id: parsedResponse[key].id,
                    benutzername: parsedResponse[key].benutzername,
                    vorname: parsedResponse[key].vorname,
                    nachname: parsedResponse[key].nachname,
                    strasse: parsedResponse[key].strasse,
                    hausnummer: parsedResponse[key].hausnummer,
                    plz: parsedResponse[key].plz,
                    ort: parsedResponse[key].ort,
                    land: parsedResponse[key].land,
                    email: parsedResponse[key].email,
                    aktiv: parsedResponse[key].aktiv,
                    erstellt: parsedResponse[key].erstellt
                };
                profiles.push(profileline);
              }

            context.commit('setProfiles', profiles);
            console.log(context);

        } catch (error) {
            console.error(error);
        }
    }

};