<template>
    <main>
      
      <h4 class="subtitle is-2">Impressum</h4>
      <div style="margin-left:300px;margin-right:300px;">




      <h4>Informationen laut §63 Gewerbeordnung, Offenlegungspflicht laut §25 Mediengesetz &amp; Informationspflicht laut §5 E-Commerce Gesetz.</h4>

      <p><br>Roland Hackl<br>Rohrbacherstraße 17,&nbsp;<br>4154 Kollerschlag,&nbsp;<br>Österreich</p>

      <p><strong>UID-Nummer:</strong>&nbsp;ATU74390223</p>

      <p></p>

      <p>Mitglied in der <strong>Wirtschaftskammer Österreich</strong><strong>Aufsichtsbehörde/Gewerbebehörde:</strong>&nbsp;BH Rohrbach</p>

      <p><strong>Tel.:</strong>       &nbsp; (+43) 660 6036399<br><strong>E-Mail:</strong>&nbsp;   <a href="mailto:office@sidanet.at">office@sidanet.at</a></p>

      <p><strong>Unternehmensgegenstand:</strong>&nbsp;Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik<br></p>






      </div>
    </main>
</template>