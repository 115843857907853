import  axios   from 'axios';

export default {
    async getphonehistory(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'adminphonehist.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            });

            // console.log(payload.sessiontoken);
            console.log(response.data);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);
            
            const phonehistory = [];

            for (const key in parsedResponse) {
                const phonehistoryline = {
                    id: parsedResponse[key].uniqueid,
                    calldate: parsedResponse[key].calldate,
                    src: parsedResponse[key].src,
                    dst: parsedResponse[key].dst,
                    dcontext: parsedResponse[key].dcontext,
                    destchannel: parsedResponse[key].destchannel,
                    lastapp: parsedResponse[key].lastapp,
                    lastdata: parsedResponse[key].lastdata,
                    duration: parsedResponse[key].duration,
                    billsec: parsedResponse[key].billsec,
                    disposition: parsedResponse[key].disposition,
                    uniqueid: parsedResponse[key].uniqueid
                };
                phonehistory.push(phonehistoryline);
              }

            context.commit('setPhonehistory', phonehistory);
            console.log('context: ' + context);

        } catch (error) {
            console.error(error);
        }
    }

};