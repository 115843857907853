export default {
    setAbos(state,payload) {
        state.abos = payload;
    },
    setAdminAbos(state,payload) {
        state.adminabos = payload;
    },
    setBillDatas(state,payload) {
        state.billdatas = payload;
    }
};