export default {
    setUserData(state,payload) {
        state.userdata = payload;
    },
    setAboType(state,payload) {
        state.abotype = payload;
    },
    setAboInfo(state,payload) {
        state.aboinfo = payload;
    },
};