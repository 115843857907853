<template>
  <div>
    <!-- <base-toast :show="!!error" title="Fehler" @close="handleError">
          <p>{{ error }}</p>
      </base-toast>
      <base-toast :show="isLoading" title="Updating..." fixed>
          <base-spinner></base-spinner>
      </base-toast> -->
      <base-dialog :show="!!updatingSuccess" title="Success" @close="handleSuccess">
            <p>Bentzerdaten aktualisiert und Aboangelegt.</p>
        </base-dialog>
        <base-dialog :show="!!error" title="Fehler" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>
    <main>
      

      <router-link to="/shop/selectabo" class="p-2 btn btn-sm text-secondary align-bottom" aria-current="page"><i class="fa-solid fa-arrow-left fa-2xs"></i> Aboauswahl</router-link>

      <div class="px-4 pt-5 my-5 text-center border-bottom">
        <h1 class="display-2 fw-light text-body-emphasis">Kassa</h1>
        <div class="overflow-hidden" style="max-height:40vh;">
          <div class="container px-5">
            <img src="../../pics/openart-image_KAJwBh7I_1726089301760_raw.jpg" class="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy">
          </div>
        </div>
      </div>


    <div class="row g-5">
      <div class="col-md-5 col-lg-4 order-md-last">
        <h4 class="d-flex justify-content-between align-items-center mb-3">
          <span class="text-secondary fs-3" style="margin-top: 4rem;">Warenkorb</span>
          <!-- <span class="badge bg-primary rounded-pill">1</span> -->
        </h4>
        <hr class="my-4">
        <ul class="list-group mb-3 fs-5">
          <li class="list-group-item d-flex justify-content-between lh-sm  placeholder-glow">
            <div>
              <h6 v-if="AboName!=false" class="my-0">{{ AboName }}</h6>
              <!-- <span v-if="!AboName" class="placeholder col-2 bg-secondary"></span>
              <span v-if="!AboDauer" class="placeholder col-7 bg-secondary"></span> -->
              <small v-if="buytype!=99" class="text-body-secondary">{{ AboDauer }} Monate</small>
              <!-- <small v-if="buytype==99" class="text-body-secondary">2 Wochen</small> -->
            </div>
            <span  class="text-body-secondary">€{{ AboPreis }}</span>
            <!-- <span v-if="!AboPreis" class="placeholder col-6 bg-secondary"></span> -->
          </li>
          
          <li class="list-group-item d-flex justify-content-between placeholder-glow">
            <span>Summe:</span>
            <strong v-if="buytype!=99" class="fs-4 ">€{{ AboPreis }}</strong>
            <strong v-if="buytype==99" class="fs-4 ">€ 0</strong>
            <!-- <span v-if="!AboPreis" class="placeholder col-6 bg-secondary"></span> -->
          </li>
        </ul>

        
      </div>
      <div class="col-md-7 col-lg-8">
        
        <h4 class="mb-3" style="margin-top: 4rem;">Benutzerdaten</h4>
        <hr class="my-4">
        <form class="needs-validation" novalidate="" @submit.prevent="submitBuyForm">
          <div class="row g-3">
            <div class="col-sm-6">
              <label for="firstname" class="form-label" >Vorname</label>
              <input type="text" class="form-control" id="firstname" placeholder="" v-model.trim="vorname" disabled>
              <!-- <div class="invalid-feedback">
                Valid first name is required.
              </div> -->
            </div>

            <div class="col-sm-6">
              <label for="lastname" class="form-label" disabled>Nachname</label>
              <input type="text" class="form-control" id="lastname" placeholder="" v-model.trim="nachname"  disabled>
              <!-- <div class="invalid-feedback">
                Valid last name is required.
              </div> -->
            </div>

            <div class="col-12">
              <label for="username" class="form-label" disabled>Benutzername</label>
              <div class="input-group mb-3">
                <span class="input-group-text">@</span>
                <input type="text" class="form-control" id="username" placeholder="Benutzername" v-model.trim="benutzername" disabled>
              <!-- <div class="invalid-feedback">
                  Your username is required.
                </div> -->
              </div>
            </div>

            

            <div class="col-12">
              <label for="email" class="form-label">Email </label>
              <input type="email" class="form-control" id="email" placeholder="benutzer@example.com"  v-model.trim="email" disabled>
              <!-- <div class="invalid-feedback">
                Please enter a valid email address for shipping updates.
              </div> -->
            </div>


            <h4 class="mb-3" style="margin-top: 4rem;">Rechnungsadresse</h4>
            
            <hr class="my-4">

            <div class="col-12">
              <label for="strasse" class="form-label">Straße</label>
              <input type="text" class="form-control" id="strasse" placeholder="Musterstraße 4" v-model.trim="strasse" required="">
              <!-- <div class="invalid-feedback">
                Please enter your shipping address.
              </div> -->
            </div>

            <div class="col-6">
              <label for="hausnr" class="form-label">Hausnummer </label>
              <input type="text" class="form-control" id="hausnr" v-model.trim="hausnr" placeholder="Hausnummer">
            </div>

            
            <div class="col-6">
              <label for="ort" class="form-label">Ort </label>
              <input type="text" class="form-control" id="ort" v-model.trim="ort" placeholder="Ort">
            </div>

            <div class="col-md-6">
              <label for="land" class="form-label">Land</label>
              <input type="text" class="form-control" id="land" placeholder="" v-model.trim="land"  required="">

              <!-- <select class="form-select" id="land" required="">
                <option value="">Auswählen...</option>
                <option>Österreich</option>
                <option>Deutschland</option>
                <option>Schweitz</option>
              </select> -->
              <!-- <div class="invalid-feedback">
                Please select a valid country.
              </div> -->
            </div>
            <div class="col-md-6">
              <label for="plz" class="form-label">PLZ</label>
              <input type="number" class="form-control" id="plz" placeholder="" v-model.trim="plz"  required="">
              <!-- <div class="invalid-feedback">
                Zip code required.
              </div> -->
            </div>

            <div class="col-12">
              <label for="rufnr" class="form-label">Rufnummer </label>
              <span v-if="IsAboRenewal" class="badge text-bg-primary">Abo wird erneuert</span>
              <input type="tel" class="form-control" id="rufnr" placeholder="...." v-model.trim="rufnr" required="true">
              <!-- <input type="text" class="form-control" id="rufnr" name="rufnr" v-model.trim="rufnr" data-inputmask="&quot;mask&quot;: &quot;0099 999 999999999&quot;" data-mask="" im-insert="true" required="true"> -->
              <span class="badge text-bg-secondary">Rufnumer die angerufen werden soll. <br>Mit Ländervorwahl ohne (+). <br>Österreich Zb.: (0043 999 9999999)</span>
            </div>

          </div>

          <!-- <div class="form-check">
            <input type="checkbox" class="form-check-input" id="save-primary">
            <label class="form-check-label" for="save-primary">Save this information for next time</label>
          </div>

          <hr class="my-4"> -->
          <div class="row g-3">
            <h4 class="mb-3" style="margin-top: 4rem;">Bezahlungsart </h4>

            <hr class="my-4">

            <div class="row" v-if="buytype !=99">
              <div class="col-6">
                <div class="form-check">
                  <input id="credit" name="paymentMethod" type="radio" class="form-check-input" checked value="1" required="" v-model="paymentMethod">
                  <label class="form-check-label" for="credit"><i class="fa-solid fa-building-columns fa-xl"></i> Überweisung</label>
                </div>
                <hr class="my-2">
                <div class="form-check">
                  <input id="paypal" name="paymentMethod" type="radio" class="form-check-input" required="" value="2" v-model="paymentMethod">
                  <label class="form-check-label" for="paypal"><i class="fa-brands fa-paypal fa-xl"></i> Paypal</label>
                </div>
                <hr class="my-2">
                <div class="form-check">
                  <input id="stripe" name="paymentMethod" type="radio" class="form-check-input" required="" value="3" v-model="paymentMethod" disabled>
                  <label class="form-check-label" for="stripe"><i class="fa-brands fa-stripe fa-xl"></i> Stripe</label>
                </div>
              </div>
              <div class="col-6">
                <button class="btn btn-primary btn-lg" @click="doUeberweisen"  :hidden="bankIsHidden">Ich überweise per Bank</button>
                <p :hidden="bankIsHidden">Ich nehme zur kenntnis, dass die aktivierung bis zu 3 Werktage dauern kann.</p>
                <div id="paypal-button-container-P-0WJ47538WV475144UM4V7FEQ" :hidden="paypalIsHidden"></div>
                <!-- <stripe-checkout-button></stripe-checkout-button> -->
              </div>
            </div>
            <div v-else>
              <p class="text-secondary">Keine Bezahlungsart zum Auswählen möglich! </p>
              <!-- Für Testen only -->
                <base-button v-if="AboId == 99" class="w-100 fw-light fs-2" type="submit">Testen <base-spinner v-if="isLoading"></base-spinner></base-button>
                <base-spinner v-if="isLoading"></base-spinner>
            </div>
            
          </div>

        </form>
        
      </div>
    </div>
    
  </main>
  </div>
</template>

<!-- eslint-disable -->
<script> 
  paypal.Buttons({
      style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
      },
      createSubscription: function(data, actions) {
        return actions.subscription.create({
          /* Creates the subscription */
          plan_id: 'P-0WJ47538WV475144UM4V7FEQ'
        });
      },
      onApprove: function(data, actions) {
        alert(data.subscriptionID); // You can add optional success message for the subscriber here
        const url = 'https://kundenportal.sidanet.at/vueapi/paypalsuccess.php?subscriptionID='+data.subscriptionID;
        console.log(url);
        // Make the request 
        fetch(url, {
                    cache: 'no-store',
                    method: 'GET',
                    headers: {
                      'Access-Control-Allow-Origin': "*",
                      "Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
                      "Access-Control-Max-Age": "86400",
                    },
                  }) 
            .then(response => response.json()) 
            .then(data => console.log(data)) 
            .catch(error => console.error('Error:', error)); 
      }
  }).render('#paypal-button-container-P-0WJ47538WV475144UM4V7FEQ'); // Renders the PayPal button -->

// import  PaypalButtons  from '../../components/ui/BasePayPalButton.vue';
// import StripeCheckoutButton from '../../components/ui/StripeCheckoutButton.vue';
export default {
  mounted() {
      
  },
  components: {
    // PaypalButtons,
    // StripeCheckoutButton,
  },
  computed: {
    bankIsHidden() {
      if (this.paymentMethod == 1){
        return false;
      } else {
        return true;
      }
    },
    paypalIsHidden() {
      if (this.paymentMethod == 2){
        return false;
      } else {
        return true;
      }
    },
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
    hasAboType() {
      console.log(this.$store.getters.hasAboType);
      return !this.isLoading && this.$store.getters.hasAboType;
    },
    AboType() {
      // console.log("AboType: ");
      // console.log(this.$store.getters.AboType);
      return !this.isLoading && this.$store.getters.AboType;
    },
    AboId() {
      // console.log("AboName: ");
      // console.log(this.$store.getters.AboType);
      if(this.$store.getters.AboType == null) {
        return '';
      } else {
        return !this.isLoading && this.$store.getters.AboType[0].id;
      }
      
    },
    AboName() {
      console.log("AboName: ");
      console.log(this.$store.getters.AboType);
      if(this.$store.getters.AboType == null) {
        return '';
      } else {
        return !this.isLoading && this.$store.getters.AboType[0].abo_name;
      }
      
    },
    AboDauer() {
      // console.log("AboDauer: ");
      // console.log(this.$store.getters.AboType);
      if(this.$store.getters.AboType == null) {
        return '';
      } else {
        return !this.isLoading && this.$store.getters.AboType[0].abo_dauer;
      }
    },
    AboPreis() {
      // console.log("AboPreis: ");
      // console.log(this.$store.getters.AboType);
      if(this.$store.getters.AboType == null) {
        return '';
      } else {
        return !this.isLoading && this.$store.getters.AboType[0].abo_preis;
      }
    },
    ResponseState() {
      console.log("ResponseState: ");
      console.log(this.$store.getters.ResponseState);
      if(this.$store.getters.ResponseState == null) {
        return '';
      } else {
        return !this.isLoading && this.$store.getters.ResponseState[0].error;
      }
    },
    IsAboRenewal() {
      if (localStorage.getItem('paymentsource') != null) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      abotype: 0,
      strasse: '',
      hausnr: '',
      land: '',
      ort: '',
      plz: '',
      vorname: '',
      nachname: '',
      benutzername: '',
      email: '',
      buytype: 0,
      paymentsource: 'new',
      paymentsourceaboid: null,
      isLoading: false,
      paymentMethod: 2,
      updatingSuccess: false,
      error: null,
      rufnr: null,
      paidFor: false,
      loaded: false,
      resultMessageText: "nothing",
      parametersPaypal: {
        show: true,
        id: this.AboName,
        amount: this.AboPreis
      }
    };
  },
  created() {
    const checkoutoption =  localStorage.getItem('checkoutoption');
    this.buytype = checkoutoption; // abo test, abo 1 , abo 3, abo 4  (DB abotyp)
    
    console.log('buytype: '+this.buytype);
    if (localStorage.getItem('paymentsource') != null) {
      this.paymentsource = localStorage.getItem('paymentsource');
      this.paymentsourceaboid = localStorage.getItem('paymentsourceaboid');
      this.loadAboRufnr();
    }
    
    this.loadAbotype();
    this.loaduserdata();

  },
  methods: {

    async loadAboRufnr(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getabo', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
          paymentsourceaboid: this.paymentsourceaboid,
        });
        this.rufnr = this.$store.getters.Abos[0].rufnummer;

      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    async loadAbotype(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getabotype', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
          abotypeid: this.buytype,
        });

      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    async loaduserdata(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getuserdata', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
        });

        this.vorname = this.$store.getters.UserData[0].vorname;
        this.nachname = this.$store.getters.UserData[0].nachname;
        this.benutzername = this.$store.getters.UserData[0].benutzername;
        this.email = this.$store.getters.UserData[0].email;
        this.strasse = this.$store.getters.UserData[0].strasse;
        this.hausnr = this.$store.getters.UserData[0].hausnummer;
        this.land = this.$store.getters.UserData[0].land;
        this.ort = this.$store.getters.UserData[0].ort;
        this.plz = this.$store.getters.UserData[0].plz;

      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    async submitBuyForm() {
      console.log('we start');
      this.isLoading = true;
      console.log("isloading true");

      //Check if all variables are set correctly !!!
      if (this.Checkinputs()) {

        //create testabo
        if (this.buytype == 99) {
          this.paymentMethod = this.buytype; // because we have no buytype when testing
          try {
            await this.$store.dispatch('testing', {
                token: this.$store.state.auth.sessiontoken,
                userid: this.$store.state.auth.userId,
                username: this.username,
                userpwd: this.userpwd,
                vorname: this.vorname,
                nachname: this.nachname,
                benutzername: this.benutzername,
                email: this.email,
                strasse: this.strasse,
                hausnr: this.hausnr,
                ort: this.ort,
                land: this.land,
                plz: this.plz,
                rufnr: this.rufnr,
                abotypeid: this.buytype,
                paymentmethod: this.paymentMethod,
                paymentsource: this.paymentsource,
            });
            this.isLoading = false;
            console.log("isloading false");

            this.updatingSuccess = true;
          } catch (error) {
            this.updatingSuccess = false;
            this.isLoading = false;
            console.log("isloading false");
            console.log(error);
            this.error = error.message;
          }
        }
        //create Buyabo
        else { 
          console.log('Checkout: we start trying..');

          console.log('this.paymentMethod: '+this.paymentMethod);
          try {
            await this.$store.dispatch('buyabo', {
                token: this.$store.state.auth.sessiontoken,
                userid: this.$store.state.auth.userId,
                username: this.username,
                userpwd: this.userpwd,
                vorname: this.vorname,
                nachname: this.nachname,
                benutzername: this.benutzername,
                email: this.email,
                strasse: this.strasse,
                hausnr: this.hausnr,
                ort: this.ort,
                land: this.land,
                plz: this.plz,
                rufnr: this.rufnr,
                abotypeid: this.buytype,
                paymentmethod: this.paymentMethod,
                paymentsource: this.paymentsource,
                paymentsourceaboid: this.paymentsourceaboid,
            });
            this.isLoading = false;
            console.log("isloading false");

            this.updatingSuccess = true;
            // if (localStorage.getItem('token') != null) {
            //     this.$router.replace('/abo');
            // }
          } catch (error) {
            this.updatingSuccess = false;
            this.isLoading = false;
            console.log("isloading false");
            console.log(error);
            this.error = error.message;
          }
        }
      } else {
          this.updatingSuccess = false;
          this.isLoading = false;
          console.log("Checkinputs false");
          this.error = 'Nicht alle Eingabefelder sind richtig befüllt, bitte alles ausfüllen!';
        }
        
    },
    handleError() {
      this.error = null;
    },
    handleSuccess() {
        this.updatingSuccess = false;
        this.$router.replace('/abos');
    },
    Checkinputs() {
      console.log('rufnr: '+ this.rufnr);
      if(this.rufnr == null) {
        return false;
      }

      return true;
    },
    doUeberweisen() {
      // hier wird einfach nur der Abo eintrag erstellt und das Abo aber noch nicht aktiviert!
      
    }
  },
};


</script>
<style>
.col {
  margin-bottom: 2rem;
}
/* .placeholder {
  opactiy: 30%;
} */

</style>