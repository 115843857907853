<template>
  <div class="card col-md-3"  style=" margin: 5px; animate">
    <div class="card-body">
      <h5 class="card-title">{{ lastapp }}  <span class="badge text-bg-secondary">{{ disposition }}</span></h5>
      <h6 class="card-subtitle mb-2 text-body-secondary"> </h6>
      <p class="card-text">{{ src }} </p>
      <p class="card-text"> {{ dst }} </p>
      <p class="card-text">{{ duration }} Sekunden </p>
      <p class="card-text">{{ calldate }}</p>
      <!-- <button class="btn btn-secondary" @click="editProfile">Bearbeiten</button> -->
    </div>
  </div>

  <!-- <div v-if="doProfileEdit">
      <div class="modal fade show" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog" style="display: block;">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalCenterTitle">Abo bearbeiten</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeEditProfile"></button>
            </div>
            <div class="modal-body">
              <input type="tel" id="editVorname" v-model="editVorname">
              <input type="tel" id="editNachname" v-model="editNachname">
              <input type="tel" id="editEmail" v-model="editEmail">
              <input type="tel" id="editStrasse" v-model="editStrasse">
              <input type="tel" id="editHausnummer" v-model="editHausnummer">
              <input type="tel" id="editPlz" v-model="editPlz">
              <input type="tel" id="editOrt" v-model="editOrt">
              <p>{{ land  }}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeEditProfile">Abbrechen</button>
              <button type="button" class="btn btn-primary">Speichern</button>
            </div>
          </div>
        </div>
      </div>
      
  </div> -->

  </template>
  <script>
  export default {
    props: ['id', 'calldate', 'src', 'dst', 'dcontext','destchannel', 'lastapp', 'lastdata', 'duration', 'billsec', 'disposition', 'uniqueid'],
    // computed: {
    //   fullName() {
    //     return this.firstName + ' ' + this.lastName;
    //   },
    //   coachContactLink() {
    //     return this.$route.path + '/' + this.id + '/contact'; // /coaches/c1/contact
    //   },
    //   coachDetailsLink() {
    //     return this.$route.path + '/' + this.id; // /coaches/c1
    //   },
    // },
    data() {
      return {
          // editVorname: '',
          // editNachname: '',
          // editEmail: '',
          // editStrasse: '',
          // editHausnummer: '',
          // editPlz: '',
          // editOrt: '',
          // editLand: '',
          // doProfileEdit: false,
      };
    },
    methods: {
      // editProfile() {
      //     this.doProfileEdit = true;
      //     this.editVorname = this.vorname,
      //     this.editNachname = this.nachname,
      //     this.editEmail = this.email,
      //     this.editStrasse = this.strasse,
      //     this.editHausnummer = this.hausnummer,
      //     this.editPlz = this.plz,
      //     this.editOrt = this.ort,
      //     this.editLand = this.land,
      //     console.log('isAuth: ' + this.$store.getters.isAuthenticated);
      //   },
      // closeEditProfilej() {
      //     this.doProfileEdit = false;
      //     console.log('isAuth: ' + this.$store.getters.isAuthenticated);
      //   }
    }
  }
  </script>
