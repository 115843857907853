<template>
    
    <div>
        <base-dialog :show="!!error" title="Fehler" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>
        <base-dialog :show="isRegistered" title="Registriert" @close="handleSuccess">
            <p>Benutzer erfolgreich registriert!</p>
            <p>Aktivierungsmail wurde gesendet.</p>
        </base-dialog>
        <base-dialog :show="isLoading" title="Registrieren..." fixed>
            <base-spinner></base-spinner>
        </base-dialog>
        <main class="form-signin w-100 m-auto">
            <form data-bitwarden-watching="1"  @submit.prevent="submitLoginForm">
                <!-- <img class="mb-4" src="/docs/5.3/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"> -->
                <h1 class="h3 mb-3 fw-normal">Register</h1>

                <div class="form-floating">
                <input type="text" class="input form-control" :class="{'is-invalid': !vornameValid}" id="firstname" placeholder="Vorname" v-model.trim="firstname" required>
                <label for="floatingInput">Vorname</label>
                </div>
                <div class="form-floating">
                <input type="text" class="input form-control" :class="{'is-invalid': !nachnameValid}" id="lastname" placeholder="Nachname" v-model.trim="lastname" required>
                <label for="floatingInput">Nachname</label>
                </div>
                <div class="form-floating">
                <input type="email" class="input form-control" :class="{'is-invalid': !mailValid}" id="email" placeholder="Email" v-model.trim="email" required>
                <label for="floatingInput">Email</label>
                </div>
                <div class="form-floating"> 
                    <!-- schauen wie man das is valid/invalid setzen kann wenn usernameValid = true ist -->
                <input type="text" class="input form-control" :class="{'is-invalid': !benutzernameValid}" id="username" placeholder="Benutzername" v-model.trim="username" required>
                <label for="floatingInput">Benutzername</label>
                </div>
                <div class="form-floating">
                <input type="password" class="form-control" :class="{'is-invalid': !passwortValid}" id="userpwd" placeholder="Passwort" v-model.trim="userpwd" required>
                <label for="floatingPassword">Passwort</label>
                </div>

                <p v-if="(errortext != '')" class="text-danger"><i class="fas fa-exclamation-triangle"></i> {{errortext}}</p>
                <!-- <button class="btn btn-primary w-100 py-2" type="submit" style="margin-top: 10px;">Registiere mich</button> -->
                <base-button class="w-100 py-2" type="submit">Registiere mich</base-button>
            </form>
        </main>
    </div>
</template>

<script>
export default {
    computed: {
        vornameValid() {
            if (this.firstname == '' && this.firstnameValid == true) {
                return true; // nix anzeigen
            } if (this.firstname.length > 0 && this.firstnameValid == true) {
                return true; // nix anzeigen
            } else{
                return false;
            }
        },
        nachnameValid() {
            if (this.lastname == '' && this.lastnameValid == true) {
                return true; // nix anzeigen
            } if (this.lastname.length > 0 && this.lastnameValid == true) {
                return true; // nix anzeigen
            } else{
                return false;
            }
        },
        mailValid() {
            if (this.email == '' && this.emailValid == true) {
                return true; // nix anzeigen
            } if (this.email.length > 0 && this.emailValid == true) {
                return true; // nix anzeigen
            } else{
                return false;
            }
        },
        benutzernameValid() {
            if (this.username == '' && this.usernameValid == true) {
                return true; // nix anzeigen
            } if (this.username.length > 0 && this.usernameValid == true) {
                return true; // nix anzeigen
            } else{
                return false;
            }
        },
        passwortValid() {
            if (this.userpwd == '' && this.passValid == true) {
                return true; // nix anzeigen
            } if (this.userpwd.length > 0 && this.passValid == true) {
                return true; // nix anzeigen
            } else{
                return false;
            }
        },
        profilesList() {
        console.log("Profiles: ");
        // console.log(this.$store.getters['profile/Profiles']);
        // return !this.isLoading && this.$store.getters['profile/Profiles'];
        console.log(this.$store.getters.Profiles);
        return !this.isLoading && this.$store.getters.Profiles;
        },
    },
    data() {
        return {
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            userpwd: '',
            formIsValid: true,
            firstnameValid: true,
            lastnameValid: true,
            emailValid: true,
            usernameValid: true,
            passValid: true,
            mode: 'signup',
            sessionId: '',
            error: null,
            errortext: '',
            isLoading: false,
            isRegistered: false,
        };
    },
    methods: {
        checkInputs() {
            this.formIsValid = true;

            // check firstname
            if (this.firstname === '') {
                this.firstnameValid = false;
                this.formIsValid = false;
                this.errortext = 'Vorname darf nicht leer sein.';
                return
            }
            // check lastname
            if (this.lastname === '') {
                this.lastnameValid = false;
                this.formIsValid = false;
                this.errortext = 'Nachname darf nicht leer sein.';
                return
            }
            // check email
            if (this.email === ''  || !this.email.includes("@")) {
                this.emailValid = false;
                this.formIsValid = false;
                this.errortext = this.email + ' darf nicht leer sein und muss ein "@" enthalten.';
                return
            }
            // check username
            if (this.username === '' || this.userpwd.includes(" ")) {
                this.usernameValid = false;
                this.formIsValid = false;
                this.errortext = 'Benutzername darf nicht leer sein und darf kein Leerzeichen enthalten.';
                return
            }
            // check userpwd
            if ( this.userpwd === '' || this.userpwd.includes(" ")) {
                this.passValid = false;
                this.formIsValid = false;
                this.errortext = 'Passwort darf nicht leer sein und darf kein Leerzeichen enthalten.';
                return
            }
        },
        async submitLoginForm() {
            await this.checkInputs();
            this.isLoading = true;
            if (this.formIsValid == true) {
                try {
                    if (this.mode === 'signup') {
                    await this.$store.dispatch('signup',{
                        benutzername: this.username,
                        vorname: this.firstname,
                        nachname: this.lastname,
                        email: this.email,
                        passwort: this.userpwd,
                    });
                    this.isLoading = false;
                    // this.$router.replace('/login');
                    this.isRegistered = true;
                    }
                } catch (error) {
                    this.isLoading = false;
                    this.error = error.message;
                }
            }
            
            
        },
        handleError() {
            this.error = null;
        },
        handleSuccess(){
            if (this.isRegistered == true) {
                
                this.$router.replace('/login');
            }
        }
        
    }
}
</script>

<style>
.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-floating {
    margin: 1rem;
}
</style>