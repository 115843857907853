<template>
    <main>
      <h4 class="subtitle is-2">Customers</h4>
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <div class="row" v-else-if="hasCustomers">
        <profile-abo-list-item
          v-for="sCustomer in customersList"
          :key="sCustomer.id"
          :id="sCustomer.id"
          :benutzername="sCustomer.benutzername"
          :vorname="sCustomer.vorname"
          :nachname="sCustomer.nachname"
          :strasse="sCustomer.strasse"
          :hausnummer="sCustomer.hausnummer"
          :plz="sCustomer.plz"
          :ort="sCustomer.ort"
          :land="sCustomer.land"
          :email="sCustomer.email"
          :aktiv="sCustomer.aktiv"
          :erstellt="sCustomer.erstellt"
        ></profile-abo-list-item>
      </div>
      <h3 v-else>Keine Daten gefunden.</h3>
    </main>
</template>

<script>
import ProfileAboListItem from '../../components/profile/ProfileAboListItem.vue';
export default {
  computed: {
    hasCustomers() {
      // console.log(this.$store.getters['profile/HasProfiles']);
      // return !this.isLoading && this.$store.getters['profile/HasProfiles'];
      console.log(this.$store.getters.HasCustomers);
      return !this.isLoading && this.$store.getters.HasCustomers;
    },
    customersList() {
      console.log("Customers: ");
      // console.log(this.$store.getters['profile/Profiles']);
      // return !this.isLoading && this.$store.getters['profile/Profiles'];
      console.log(this.$store.getters.Customers);
      return !this.isLoading && this.$store.getters.Customers;
    },
  },
  components: {
    ProfileAboListItem,
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.loadCustomers();
  },
  methods: {
    async loadCustomers(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getcustomers', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
        });

      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>