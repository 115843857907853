<template>



<footer class="d-flex fixed-bottom flex-wrap justify-content-between align-items-center py-3 border-top mt-auto bg-body-tertiary">
  <div class="col-md-4 d-flex align-items-center">
    <a href="/" class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
      <svg class="bi" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
    </a>
    <span class="mb-3 mb-md-0 text-body-secondary">© 2024 SIDANET</span>
  </div>

  <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
    <li class="ms-3"><a class="text-body-secondary" href="#"></a></li>
    <li class="ms-3"><a class="text-body-secondary" href="#"></a></li>
    <li class="ms-3"><a class="text-body-secondary" href="#"></a></li>
    <li class="ms-3"><router-link to="/dataprotection" class="btn text-body-secondary" style="margin:5px;"><i class="fa fa-shield text-primary"></i> Datenschutz</router-link></li>
    <li class="ms-3"><router-link to="/impressum" class="btn text-body-secondary" style="margin:5px;"><i class="fa fa-align-left text-primary"></i> Impressum</router-link></li>
  </ul>
</footer>


</template>