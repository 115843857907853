export default {
    UserData(state) {
        return state.userdata;
    },
    HasUserData(state) {
        return state.userdata && state.userdata.length > 0;
    },
    AboType(state) {
        return state.abotype;
    },
    HasAboType(state) {
        return state.abotype && state.abotype.length > 0;
    },
    AboInfo(state) {
        return state.aboinfo;
    },
    HasAboInfo(state) {
        return state.aboinfo && state.aboinfo.length > 0;
    },

};