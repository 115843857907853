import  axios   from 'axios';

export default {
    async login(context,payload) {
        // console.log(context,payload);
        // try {
            const httplink = context.state.serverlink+'vuelogin.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            username: payload.username,
            userpwd: payload.userpwd,
            });

            console.log(response.data);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);

            if(parsedResponse.status == "success") {

                localStorage.setItem('token',parsedResponse.sessiontoken);
                localStorage.setItem('userId',parsedResponse.userId);
                localStorage.setItem('userN',parsedResponse.uname);

                context.commit('setUser', {
                    sessiontoken: parsedResponse.sessiontoken,
                    userId: parsedResponse.userId,
                    tokenExpiration: parsedResponse.expiresIn,
                    username: parsedResponse.uname,
                    isloggedin: true
                });
                console.log(context);
                console.log(parsedResponse.uname);
                // if (parsedResponse.uname == "admin") {
                //     console.log("set to darkmode" ||parsedResponse.uname);
                //     document.body.setAttribute("data-bs-theme","dark");   //now i have to save the preference to user and load it on login
                // }
                
            } else {
                
                console.log(parsedResponse.status + ': ' + parsedResponse.message);
                const error = new Error('Fehlernachricht: ' + parsedResponse.message);
                throw error;
            }

        // } catch (error) {
        //     console.error(error);
        // }
    },
    tryLogin(context) {
        const token =  localStorage.getItem('token');
        const userId =  localStorage.getItem('userId');
        const userN =  localStorage.getItem('userN');
        const expirationDate = new Date();
        console.log('try to login with ' + userId);
        

        if (token && userId) {
            console.log("found the user and set the values "+token+" "+userN);
            context.commit('setUser', {
                sessiontoken: token,
                userId: userId,
                tokenExpiration: expirationDate,
                username: userN,
                isloggedin: true
            });

            console.log(userN);
            // if (userN == "admin") {
            //     console.log("set to darkmode" ||userN);
            //     document.body.setAttribute("data-bs-theme","dark");   //now i have to save the preference to user and load it on login
            // }
        } 
        // else {
        //     console.log("found the user and set the values");
        //     context.commit('setUser', {
        //         sessiontoken: null,
        //         userId: null,
        //         tokenExpiration: null,
        //         username: null
        //     });
        //     console.log(context);
        // }
    },
    async logout(context) {
        try {
            console.log("context: ");
            console.log(context);
            console.log(context.state.userId);

            const httplink = context.state.serverlink+'vuelogout.php?key=9999';
            console.log(httplink);
            const response = await axios.post(httplink, {
            sessiontoken: context.state.sessiontoken,
            userId: context.state.userId,
            });
            console.log(httplink);
            console.log(response.data);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);

            if(parsedResponse.status == "success") {
                context.commit('setUser', {
                    sessiontoken: null,
                    userId: null,
                    tokenExpiration: null,
                    username: null,
                    isloggedin: false
                });

                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('userN');
                // this.$router.replace('/login');
                console.log(context);

                document.body.setAttribute("data-bs-theme","light");
            } else {
                
                console.log('Session could not be closed.');

            }

        } catch (error) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('userN');
            console.error(error);
        }
    },
    async signup(context,payload) {
        // console.log(context,payload);
        // try {
            const httplink = context.state.serverlink+'registeruser.php?key=9999';
            console.log(payload);
            const response = await axios.post(httplink, {
            benutzername: payload.benutzername,
            passwort: payload.passwort,
            vorname: payload.vorname,
            nachname: payload.nachname,
            email: payload.email,
            });

            console.log(response.data);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);

            if(parsedResponse.status == "success") {

                localStorage.setItem('token',parsedResponse.sessiontoken);
                localStorage.setItem('userId',parsedResponse.userId);
                localStorage.setItem('userN',parsedResponse.uname);

                context.commit('setUser', {
                    sessiontoken: parsedResponse.sessiontoken,
                    userId: parsedResponse.userId,
                    tokenExpiration: parsedResponse.expiresIn,
                    username: parsedResponse.uname,
                    isloggedin: true
                });
                console.log(context);
                console.log(parsedResponse.uname);
                if (parsedResponse.uname == "admin") {
                    console.log("set to darkmode" ||parsedResponse.uname);
                    document.body.setAttribute("data-bs-theme","dark");   //now i have to save the preference to user and load it on login
                }
            } else {
                console.log(parsedResponse.status + ': ' + parsedResponse.message);
                const error = new Error('Fehlernachricht: ' + parsedResponse.message);
                throw error;
            }

        // } catch (error) {
        //     console.error(error);
        // }
    },

};