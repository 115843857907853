import { createApp } from 'vue';

import router from './router.js';
import store from './store/index.js';
import App from './App.vue';
import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import BaseModal from './components/ui/BaseModal.vue';
// import VueTelInput from 'vue-tel-input';
// import BaseToast from './components/ui/BaseToast.vue';


// import BootstrapVue from "bootstrap-vue";

// import "../assets/scss/custom.scss";
// import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap-custom.scss"

const app = createApp(App);

app.use(router);
app.use(store);
// app.use(VueTelInput);
// app.use(BootstrapVue);

app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
app.component('base-modal', BaseModal);
// app.component('base-toast', BaseToast);


app.mount('#app');


// import "../node_modules/bootstrap/dist/css/bootstrap.css";
// import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
// import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
