<template>
    <main>
      <router-link to="/shop" class="p-2 btn btn-sm text-secondary align-bottom" aria-current="page"><i class="fa-solid fa-arrow-left fa-2xs"></i> zum Shop</router-link>

      <div>
        <button @click="startPayment" class="stripe-button">
          Pay with Stripe
        </button>
        <p v-if="error" class="error">{{ error }}</p>
      </div>


  </main>
</template>


<script>
/* global Stripe */
export default {
  components: {
  },
  computed: {
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      stripe: null,
      stripePublicKey: "pk_test_51QEVNUQ4cdql9vllk5kC3ykjLTb2CmAkNFaI6W4eqlzJC30xT1UoHKiAO4Wk0QEi827OYGQqtIQQwOk0kcHFDQBo00I25kHofE", // Replace with your public key


    };
  },
  mounted() {
    // Initialize Stripe with your public key
    this.stripe = Stripe(this.stripePublicKey);

  },
  methods: {
    handleError() {
      this.error = null;
    },
    async startPayment() {
      try {
        const { error } = await this.stripe.redirectToCheckout({
          lineItems: [
            {
              price: "price_1QEVfZQ4cdql9vllNrahLpQt", // Replace with your price ID
              quantity: 1,
            },
          ],
          mode: "payment",
          clientReferenceId: "aboId45678",
          sessionId: "SessionAboId45678",
          customerEmail: "test@sidanet.at",
          successUrl: window.location.origin + "/abo",
          cancelUrl: window.location.origin + "/testbuy",
        });
        console.log(error);
        if (error) {
          this.error = error.message;
        } else{
          console.log("We have payed the price to get the Abo!");
          this.error = "Bezahlt ... jetzt gehts los !";
        }

        
      } catch (err) {
        console.log(err);
        this.error = "Payment failed. Please try again.";
      }
    },
  },
};
</script>

<style>
.col {
  margin-bottom: 2rem;
}

.stripe-button {
  background-color: #6772e5;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.stripe-button:hover {
  background-color: #5469d4;
}
.error {
  color: red;
  margin-top: 10px;
}
</style>