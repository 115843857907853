<template>

  <div class="card col-md-3" style=" margin: 5px;">
    <span class="badge text-bg-danger"  v-if="isExpired">Abgelaufen</span>
    <span class="badge text-bg-success"  v-if="!isExpired">Aktiv</span>
    <span class="badge text-bg-warning"  v-if="!isPayed">nicht Bezahlt</span>
    <div class="card-body">
      <h5 class="card-title">{{ abo_name }}</h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">{{ rufnummer }}</h6>
      <p class="card-text">{{ erstellt }}<span class="badge text-bg-primary"  v-if="!isPayed"> {{ abo_dauer }} Monate</span></p>
      <p class="card-text">RE-{{ id }} </p>
      <p class="card-text">{{ Bezahltyp_id }}  </p>
      <!-- <p class="card-text"> {{ bezahlt_seit }}</p> -->
      <!-- <p>{{ Aktivierungskey }}</p> -->
      <!-- <p class="card-text">{{ erstellt }}</p> -->
      <div class="btn-group" role="group">
        <button class="btn btn-secondary" style="margin: 1px;" @click="editAbo"><i class="fa-solid fa-gear"></i> Bearbeiten</button>
        <button class="btn btn-primary"  style="margin: 1px;" @click="showRe"><i class="fas fa-file-invoice-dollar"></i> Rechnung</button>
      </div>
    </div>
  </div>


  <div v-if="doEdit">
      <div class="modal fade show" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog" style="display: block;">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalCenterTitle">Abo bearbeiten</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeEditAbo"></button>
            </div>
            <div class="modal-body">
              <div class="input-group mb-3">
                <span class="input-group-text"><i class="fas fa-phone"></i></span>
                <input type="tel" class="form-control" placeholder="Tel." id="editTel"  v-model="editTel">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeEditAbo">Abbrechen</button>
              <button type="button" class="btn btn-success">Verlängern</button>
              <button type="button" class="btn btn-primary">Speichern</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show" @click="closeRe"></div>
  </div>

  <div v-if="showrechnung">
    <Abo-re-modal v-if="showrechnung"
      @EButtonCloseRe="closeRe"
      :id="id">
    </Abo-re-modal>
  </div>


</template>


<script>
import AboReModal from '../../Pages/abo/AboReModal.vue';
// import VueHtml2pdf from '../../../node_modules/vue-html2pdf/src/vue-html2pdf.vue';

export default {
  props: [
    'id', 
    'Abotyp', 
    'abo_name', 
    'abotyp_id', 
    'abo_dauer',
    'erstellt', 
    'bezahlt', 
    'bezahlt_seit', 
    'miniserver_ip', 
    'rufnummer', 
    'Aktivierungskey', 
    'Rufnummernbestaetigung', 
    'Bezahltyp_id',
    'is_expired'
  ],
  components: {
    AboReModal,
    // VueHtml2pdf,
  },
  computed: {
    isExpired() {
      if (this.is_expired == 1) {
        console.log(true);
        return true;
      } else {
        console.log(false);
        return false;
      }
      
    },
    isPayed() {
      if (this.bezahlt == 1) {
        console.log(true);
        return true;
      } else {
        console.log(false);
        return false;
      }
      
    },
  },
  data() {
    return {
      doEdit: false,
      showrechnung: false,
      editTel: '',
    };
  },
  methods: {
    generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
    editAbo() {
        this.doEdit = true;
        this.editTel = this.rufnummer,
        console.log('isAuth: ' + this.$store.getters.isAuthenticated);
      },
    closeEditAbo() {
        this.doEdit = false;
        console.log('isAuth: ' + this.$store.getters.isAuthenticated);
      },
    showRe() {
        this.showrechnung = true;
        this.editTel = this.rufnummer,
        console.log('isAuth: ' + this.$store.getters.isAuthenticated);
      },
    closeRe() {
        this.showrechnung = false;
        console.log('isAuth: ' + this.$store.getters.isAuthenticated);
      },
  }
};
</script>
