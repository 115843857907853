

<template>

      <div class="modal fade show" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog" style="display: block;">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalCenterTitle">Rechnung</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('EButtonCloseRe')"></button>
            </div>
            <div v-if="isLoading">
                <base-spinner></base-spinner>
            </div>
            <div v-else class="modal-body">
              <div v-for="item in reData" :key="item.id" class="container-fluid" >
                <div class="row">
                  <div class="col-md-8 fst-italic">Roland Hackl - Rohrbacherstraße 17 - 4154 Kollerschlag</div> <div class="col-md-4"><img src="../../Sidanet-logo-Text2-e1570884482296.png" href="index.html" alt="Image" class="img-fluid" style="width: 200px;"></div>
                  <br>
                  <br>
                  <br>
                  <br>
                  <div class="col-md-8">{{ item.Vorname }} {{ item.Nachname }} </div><div class="col-md-4 "></div>
                  <div class="col-md-8">{{ item.Strasse }} {{ item.Hausnummer }}</div><div class="col-md-4">Rechnungsnummer: RE-{{ item.reNr }} </div>
                  <div class="col-md-8">{{ item.Plz }} {{ item.Ort }}</div><div class="col-md-4"> Rechnungsdatum: {{ item.erstellt }} </div>
                  <div class="col-md-8">{{ item.Land }}</div><div class="col-md-4"> Lieferdatum: {{ item.erstellt }} </div>
                  <div class="col-md-8"> </div><div class="col-md-4"> </div>
                </div>
                <br>
                <br>
                <br>
                <br>
                <div class="row">
                    <h5 class="col-md-12 ms-auto">Rechnung RE-{{ item.reNr }}</h5>
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">Menge</th>
                            <th scope="col">Name</th>
                            <th></th>
                            <th scope="col">Einzelpreis Netto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>{{ item.menge}}</td>
                            <td>{{ item.abo_name}}</td>
                            <td></td>
                            <td>{{ item.Preis_Netto}}</td>
                            </tr>
                            <tr>
                            <td></td>
                            <td></td>
                            <td>zzgl. Umsatzsteuer 20%</td>
                            <td>{{ item.Preis_Mwst}}</td>
                            </tr>
                            <tr>
                            <td></td>
                            <td></td>
                            <td>Gesamtbetrag Brutto</td>
                            <td class="fs-3 text">{{ item.Abo_Preis}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <br>
                <br>

                <div class="row">
                  <div class="col-md-3">
                    Roland Hackl<br>
                    Sidanet<br>
                    Rohrbacherstraße 17<br>
                    4154 Kollerschlag<br>
                    Österreich<br>
                  </div>
                  <div class="col-md-3">
                    Tel.: 06606036399<br>
                    E-Mail: office@sidanet.at<br>
                    Web: www.sidanet.at<br>
                  </div>
                  <div class="col-md-3">
                    USt.-ID: ATU74390223<br>
                    Steuer-Nr.: 52 250 0032<br>
                    Inhaber/-in: Roland Hackl<br>
                  </div>
                  <div class="col-md-3">
                    Sparkasse Mühlviertel-West<br>
                    BLZ: 20334<br>
                    IBAN: AT40 2033 4010 0006 7262<br>
                    BIC: SMWRAT21XXX<br>
                </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-sm-9">
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="$emit('EButtonCloseRe')">Abbrechen</button>
              <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="downloadRe">Herunterladen</button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>


  </template>


<script>
export default {
  props: [
    'id', 
  ],
  emits: ['EButtonCloseRe'],
  components: {
  },
  computed: {
    HasBillDatas() {
      console.log(this.$store.getters.HasBillDatas);
      return !this.isLoading && this.$store.getters.HasBillDatas;
    },
    reData() {
      console.log(this.$store.getters.BillDatas);
      return !this.isLoading && this.$store.getters.BillDatas;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.loadReData();
  },
  methods: {
    showRe() {
        this.showrechnung = true;
        this.editTel = this.rufnummer,
        console.log('isAuth: ' + this.$store.getters.isAuthenticated);
      },
    closeRe() {
        this.showrechnung = false;
        console.log('isAuth: ' + this.$store.getters.isAuthenticated);
      },
    async loadReData(refresh = false) {
        this.isLoading = true;
        try {
        await this.$store.dispatch('getrechnungsdaten', {
            forceRefresh: refresh,
            token: this.$store.state.auth.sessiontoken,
            userid: this.$store.state.auth.userId,
            reid: this.id,
        });
        console.log("daten holen für modal " || this.id);
        } catch (error) {
        this.error = error.message || 'Something went wrong!';
        }
        this.isLoading = false;
  },
  downloadRe() {
    window.print();
  }  ,
handleError() {
    this.error = null;
},
  }
};
</script>


